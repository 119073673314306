<script>
export default {
  props: {
    dataClothSizes: {
      type: [Array, Object],
      required: true,
    }
  },
  data() {
    return {
      apiUrl: {
        cloth: process.env.VUE_APP_API_BASE_URL + 'clothes'
      }
    }
  },
  methods: {
    submitMinStock () {
      let submit_data = []
      this.dataClothSizes.forEach((clothSize, index) => {
        submit_data.push({
          size_id: clothSize.size.id,
          cloth_size_prices: []
        })
        clothSize.cloth_size_prices.forEach(clothPrice => {
          submit_data[index].cloth_size_prices.push({
            cloth_price_type_id: clothPrice.cloth_price_type_id,
            price: clothPrice.price
          })
        })
      })
      console.log(submit_data)
      this.$http.patch(
        `${this.apiUrl.cloth}/${this.$route.params.id}/price`,
        {
          cloth_sizes: submit_data,
        },
      )
      .then(() => {
        this.closeModal()
        this.$emit('closeModalComponent')
        this.$store.dispatch(
          "notification/success",
          "Data berhasil disimpan"
        );
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          console.log(error);
        }
        this.$store.dispatch("notification/error", error);
      });
    },
    showModal () {
      this.$refs["modal-edit-min-stock"].show();
    },
    closeModal () {
      this.$refs["modal-edit-min-stock"].hide();
    }
  }
}
</script>

<template>
  <div>
    <b-button
      variant="warning"
      type="button"
      class="btn mx-2 waves-effect waves-light fw-bold"
      @click="showModal"
      >Edit Harga</b-button
    >
    <b-modal id="modal-edit-price" size="xl" centered modal-header-close ref="modal-edit-min-stock" hide-footer>
      <template #modal-title>
        Edit Harga
      </template>
      <form @submit.prevent="submitMinStock">
        <table class="table table-striped table-bordered">
          <thead>
            <th>Ukuran</th>
            <th class="text-end" v-for="dataPrice in dataClothSizes[0].cloth_size_prices">{{ dataPrice.cloth_price_type.name }}</th>
          </thead>
          <tbody>
            <tr v-for="dataSize in dataClothSizes">
              <td>{{ dataSize.size.name }}</td>
              <td class="text-end p-0" v-for="dataPrice in dataSize.cloth_size_prices">
                <b-form-group
                  id="fieldset-1"
                  label-for="input-1"
                >
                  <b-form-input class="text-end" type="number" id="input-1" v-model="dataPrice.price"></b-form-input>
                </b-form-group>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <div class="d-block">
          <b-form-group
            id="fieldset-1"
            label="Min. Stock"
            label-for="input-1"
          >
            <b-form-input type="number" id="input-1" v-model="minStock"></b-form-input>
          </b-form-group>
        </div> -->
        <div class="d-flex justify-content-between">
          <b-button variant="primary" type="submit" class="mt-3" block >Submit</b-button>
          <b-button class="mt-3" block @click="closeModal">Batal</b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>