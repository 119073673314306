<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import axios from "axios";
import { convertDateTime, convertPrice, detectScreen } from "@/_helpers/index";
import { BFormFile } from "bootstrap-vue";
import customTable from "@/components/paginate-table.vue";
import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";
import skeletonLoading from "@/components/skeleton";
import imgDummy from "@/assets/images/kaosnyaman.png";

/**
 * Starter page
 */
export default {
  components: {
    Layout,
    PageHeader,
    BFormFile,
    skeletonLoading,
    customTable,
  },
  page: {
    title: "Detail Produk",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Produk",
        },
        {
          text: this.$route.name,
          active: true,
        },
      ],
      columns: [
        {
          prop: "cloth_id",
          label: "Pakaian",
          sortable: true,
          center: true,
          cloth_id: true,
          width: "300",
        },
        {
          prop: "created_by",
          label: "Dibuat Oleh",
          sortable: true,
          center: true,
          created_history: true,
          width: "200",
        },
        {
          prop: "updated_by",
          label: "Diperbarui Oleh",
          sortable: true,
          center: true,
          updated_history: true,
          width: "200",
        },
        {
          prop: "referral_id",
          label: "Id Referral",
          sortable: true,
          center: true,
          width: "300",
        },
        {
          prop: "referral",
          label: "Referral",
          sortable: true,
          center: true,
          width: "200",
        },
        {
          prop: "note",
          label: "Catatan",
          sortable: true,
          center: true,
          width: "300",
        },
        {
          prop: "created_at",
          label: "Tgl Dibuat",
          sortable: true,
          center: true,
          date: true,
          width: "200",
        },
        {
          prop: "updated_at",
          label: "Tgl Diubah",
          sortable: true,
          center: true,
          date: true,
          width: "200",
        },
      ],
      isLoading: false,
      setData: [],
      apiUrl: {
        baseUrl: process.env.VUE_APP_API_BASE_URL_NULL,
        color: process.env.VUE_APP_API_BASE_URL + "colors",
        size: process.env.VUE_APP_API_BASE_URL + "sizes",
        cloth: process.env.VUE_APP_API_BASE_URL + "clothes",
        clothPhoto: process.env.VUE_APP_API_BASE_URL + "cloth-photos",
        clothPriceType: process.env.VUE_APP_API_BASE_URL + "cloth-price-types",
        clothColors: process.env.VUE_APP_API_BASE_URL + "cloth-colors",
        clothSizes: process.env.VUE_APP_API_BASE_URL + "cloth-sizes",
        clothHistory: process.env.VUE_APP_API_BASE_URL + "cloth-history-logs",
      },
      setColor: [],
      setSize: [],
      setSizeModal: [],
      setClothPriceType: [],
      selectAllColors: false,
      selectAllSizes: false,
      checkedColors: [],
      checkedSizes: [],
      setFields: {
        cloth_color: [],
      },
      selectAllSizes2: false,
      checkedSizes2: [],
      setFields2: {
        cloth_size: [],
      },
      setPhoto: [],
      clothPhoto: {
        image: null,
        cloth_id: this.$route.params.id,
      },
      colorPhoto: {
        id_color: "",
        image: null,
      },
      isDisabled: false,
      sku: "SKU Awal",
      editing: false,
      editSku: "",
      isLoadingButton: false,
      spesificIdSize: null,
      spesificPrices: null,
      spesificStock: 0,
      spesificMinStock: 0,
      spesificUpdatePrice: {
        stock: null,
        cloth_size_prices: [],
      },
      screenWidth: 0,
      clothSizeId: null,
      selectedPhoto: null,
      imgDummy: imgDummy,
      smallImg: 4,
    };
  },
  watch: {
    spesificPrices: {
      handler(newPrices) {
        this.spesificUpdatePrice.cloth_size_prices = newPrices.map((price) => {
          return {
            id: price.id,
            price: Number(price.price),
          };
        });
        this.spesificUpdatePrice.stock = Number(this.spesificStock);
        this.spesificUpdatePrice.stock = Number(this.spesificStock);
      },
      deep: true,
    },
    setData: {
      deep: true,
      handler() {},
    },
    checkedColors: function(newVal) {
      this.setFields.cloth_color = newVal.map((colorId) => {
        let sku = this.skus !== undefined ? this.skus[colorId] : null;
        if (sku === undefined) {
          sku = null;
        }
        let existingColor = this.setFields.cloth_color.find(
          (color) => color.color_id === colorId
        );
        let cloth_size = existingColor
          ? existingColor.cloth_size
          : this.checkedSizes.map((sizeId) => ({
              size_id: sizeId,
              stock: null,
              cloth_price: this.setClothPriceType.map((priceType) => ({
                cloth_price_type_id: priceType.id,
                price: null,
              })),
            }));

        return {
          color_id: colorId,
          sku: sku,
          cloth_size: cloth_size,
        };
      });
    },
    checkedSizes(newVal) {
      this.setFields.cloth_color.forEach((color) => {
        color.cloth_size = newVal.map((sizeId) => {
          let existingSize = color.cloth_size.find(
            (size) => size.size_id === sizeId
          );
          let stock = existingSize ? existingSize.stock : null;
          let cloth_price = this.setClothPriceType.map((priceType) => ({
            cloth_price_type_id: priceType.id,
            price: existingSize
              ? existingSize.cloth_price.find(
                  (price) => price.cloth_price_type_id === priceType.id
                )?.price
              : null,
          }));

          return {
            size_id: sizeId,
            stock: stock,
            cloth_price: cloth_price,
          };
        });
      });
    },
    checkedSizes2(newVal) {
      this.setFields2.cloth_size = newVal.map((sizeId) => {
        let existingSize = this.setFields2.cloth_size.find(
          (size) => size.size_id === sizeId
        );
        let stock = existingSize ? existingSize.stock : null;
        let cloth_price = this.setClothPriceType.map((priceType) => ({
          cloth_price_type_id: priceType.id,
          price: existingSize
            ? existingSize.cloth_price.find(
                (price) => price.cloth_price_type_id === priceType.id
              )?.price
            : null,
        }));

        return {
          size_id: sizeId,
          stock: stock,
          cloth_price: cloth_price,
        };
      });
    },
    selectAllColors(newVal) {
      if (newVal) {
        this.checkedColors = this.setColor.map((color) => color.id);
      } else {
        this.checkedColors = [];
      }
    },
    selectAllSizes(newVal) {
      if (newVal) {
        this.checkedSizes = this.setSize.map((size) => size.id);
      } else {
        this.checkedSizes = [];
      }
    },
    selectAllSizes2(newVal) {
      if (newVal) {
        this.checkedSizes2 = this.setSize.map((size) => size.id);
      } else {
        this.checkedSizes2 = [];
      }
    },
  },
  created() {},
  mounted() {
    this.getClothPhoto();
    this.getDetail();
    this.getColor();
    this.getSize();
    this.getClothPriceType();
    this.updateScreenWidth();
    this.onScreenResize();
  },
  computed: {
    filteredSizes() {
      if (this.setData.cloth_colors !== undefined) {
        return this.setSizeModal.filter((size) => {
          return this.setData.cloth_colors.filter((color) => {
            return color.cloth_sizes.filter((clothSize) => {
              return clothSize.size.id === size.id;
            });
          });
        });
      }
    },
    checkedColorErrors() {
      return this.$v.checkedColors;
    },
    clothColorErrors() {
      return this.$v.setFields.cloth_color.$each;
    },
    clothSizeErrors() {
      return this.$v.setFields2.cloth_size.$each;
    },
    checkedSizeErrors() {
      return this.$v.checkedSizes;
    },
    checkedSize2Errors() {
      return this.$v.checkedSizes2;
    },
    clothPhotoErrors() {
      return this.$v.clothPhoto.image;
    },
    // colorPhotoErrors() {
    //   // return this.$v.colorPhoto.image;
    //   console.log(this.$v)
    // },
  },
  validations: {
    setFields: {
      cloth_color: {
        $each: {
          sku: {
            required,
          },
          cloth_size: {
            $each: {
              stock: {
                required,
              },
              cloth_price: {
                $each: {
                  price: {
                    required,
                  },
                },
              },
            },
          },
        },
      },
    },
    setFields2: {
      cloth_size: {
        $each: {
          stock: {
            required,
          },
          cloth_price: {
            $each: {
              price: {
                required,
              },
            },
          },
        },
      },
    },
    checkedColors: {
      required,
      minLength: minLength(1),
    },
    checkedSizes: {
      required,
      minLength: minLength(1),
    },
    checkedSizes2: {
      required,
      minLength: minLength(1),
    },
    clothPhoto: {
      image: {
        required,
      },
    },
  },
  methods: {
    selectPhoto(photoSrc) {
      this.selectedPhoto = photoSrc;
    },
    showEditForm(sku) {
      this.editing = true;
      this.editSku = sku;
    },
    onScreenResize() {
      window.addEventListener("resize", () => {
        this.updateScreenWidth();
      });
    },
    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
    async saveEditSku(id_color) {
      try {
        this.isLoading = true;
        await axios
          .patch(
            `${this.apiUrl.clothColors}/${id_color}`,
            { sku: this.editSku },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then(async () => {
            this.isLoading = false;
            this.editing = false;
            await this.getDetail();
            this.$notify({
              message: "SKU Berhasil Diubah",
              position: "bottom-right",
              type: "success",
              duration: 5000,
            });
          });
      } catch (error) {
        this.isLoading = false;
        if (process.env.NODE_ENV !== "production") {
          console.log(error);
        }
        this.$store.dispatch("notification/error", error);
        this.$notify.error({
          message: this.notification.message,
          position: "bottom-right",
          duration: 5000,
        });
      }
    },
    cancelEditSku() {
      this.editing = false;
    },
    async getDetail() {
      try {
        this.isLoading = true;
        await axios
          .get(`${this.apiUrl.cloth}/${this.$route.params.id}`)
          .then((response) => {
            this.setData = response.data;
            this.isLoading = false;
          });
      } catch (error) {
        console.error(error);
        if (error.response.status == 404) {
          this.$router.push("/404-notfound");
        }
      }
    },
    formattedDate(value) {
      return convertDateTime(value);
    },
    formattedPrice(value) {
      return convertPrice(value);
    },
    async getColor() {
      try {
        this.isLoading = true;
        await axios.get(`${this.apiUrl.color}?limit=1000`).then((response) => {
          this.setColor = response.data.data;
          this.isLoading = false;
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getSize() {
      try {
        this.isLoading = true;
        await axios.get(`${this.apiUrl.size}?limit=1000`).then((response) => {
          this.setSize = response.data.data;
          this.setSizeModal = response.data.data;
          this.isLoading = false;
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getClothPriceType() {
      try {
        this.isLoading = true;
        await axios
          .get(`${this.apiUrl.clothPriceType}?limit=1000`)
          .then((response) => {
            this.setClothPriceType = response.data.data;
            this.isLoading = false;
          });
      } catch (error) {
        console.error(error);
      }
    },
    async getClothPhoto() {
      try {
        this.isLoading = true;
        await axios
          .get(`${this.apiUrl.clothPhoto}?cloth_id=${this.clothPhoto.cloth_id}`)
          .then((response) => {
            this.setPhoto = response.data.data;
            this.isLoading = false;
          });
      } catch (error) {
        console.error(error);
      }
    },
    async changeStatus(check) {
      if (this.setData.status == check) {
        try {
          this.isDisabled = true;
          this.isLoading = true;
          const setStatus = check ? 1 : 0;
          await axios
            .patch(
              `${this.apiUrl.cloth}/${this.$route.params.id}`,
              { status: setStatus },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then(async () => {
              this.isLoading = false;
              await this.getDetail();
              this.$notify({
                message: "Data Berhasil di Simpan",
                position: "bottom-right",
                type: "success",
                duration: 5000,
              });
            });
        } catch (error) {
          this.isLoading = false;
          if (process.env.NODE_ENV !== "production") {
            console.log(error);
          }
          this.$store.dispatch("notification/error", error);
          this.$notify.error({
            message: this.notification.message,
            position: "bottom-right",
            duration: 5000,
          });
        }
      }
    },
    showModalColorPhoto(id) {
      this.colorPhoto.id_color = id;
      this.$refs["modal-color-photo"].show();
    },
    hideModalColorPhoto() {
      this.$refs["modal-color-photo"].hide();
    },
    showModalPhoto() {
      this.$refs["modal-cloth-photo"].show();
    },
    hideModalPhoto() {
      this.$refs["modal-cloth-photo"].hide();
    },
    showModalColor() {
      this.$refs["modal-cloth-color"].show();
    },
    hideModalColor() {
      if (this.$refs["modal-cloth-color"]) {
        this.$refs["modal-cloth-color"].hide();
      }
      this.setFields.cloth_color = [];
      this.selectAllColors = false;
      this.selectAllSizes = false;
      this.checkedColors = [];
      this.checkedSizes = [];
    },
    showModalSize() {
      this.$refs["modal-cloth-size"].show();
    },
    hideModalSize() {
      this.$refs["modal-cloth-size"].hide();
      this.setFields2.cloth_size = [];
      this.selectAllSizes2 = false;
      this.checkedSizes2 = [];
    },
    showModalSizeEdit(spesific_cloth_size) {
      this.spesificIdSize = spesific_cloth_size.id;
      this.spesificStock = spesific_cloth_size.stock;
      this.spesificMinStock = spesific_cloth_size.min_stock;
      this.spesificPrices = spesific_cloth_size.cloth_size_prices;
      this.$refs["modal-cloth-size-edit"].show();
    },
    hideModalSizeEdit() {
      this.$refs["modal-cloth-size-edit"].hide();
    },
    async onSubmitColor() {
      this.$v.setFields.$touch();
      this.$v.checkedColors.$touch();
      this.$v.checkedSizes.$touch();
      if (
        !this.$v.setFields.$invalid &&
        !this.$v.checkedColors.$invalid &&
        !this.$v.checkedSizes.$invalid
      ) {
        try {
          // console.log(this.setFields)
          this.isLoading = true;
          await axios
            .post(
              `${this.apiUrl.cloth}/${this.$route.params.id}/color`,
              this.setFields,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then(async () => {
              this.isLoading = false;
              this.hideModalColor();
              await this.getDetail();
              this.$store.dispatch(
                "notification/success",
                "Data berhasil disimpan"
              );
            });
        } catch (error) {
          this.isLoading = false;
          if (process.env.NODE_ENV !== "production") {
            console.log(error);
          }
          this.$store.dispatch("notification/error", error);
        }
      }
    },
    updateSku(colorId, sku) {
      if (!this.skus) {
        this.skus = {};
      }
      this.$set(this.skus, colorId, sku);
    },
    async onSubmitSize() {
      this.$v.setFields2.$touch();
      this.$v.checkedSizes2.$touch();
      if (!this.$v.setFields2.$invalid && !this.$v.checkedSizes2.$invalid) {
        try {
          console.log(this.setFields2);
          this.isLoading = true;
          await this.$http
            .post(
              // `${this.apiUrl.cloth}/${this.$route.params.id}`,
              `${this.apiUrl.cloth}/${this.$route.params.id}/color/${this.clothSizeId}/size`,
              this.setFields2,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then(async () => {
              this.isLoading = false;
              // this.hideModalSize();
              await this.getDetail();
              this.$store.dispatch(
                "notification/success",
                "Data berhasil disimpan"
              );
            });
        } catch (error) {
          this.isLoading = false;
          if (process.env.NODE_ENV !== "production") {
            console.log(error);
          }
          this.$store.dispatch("notification/error", error);
        }
      }
    },
    async onSubmitClothPhoto() {
      this.$v.clothPhoto.$touch();
      if (!this.$v.clothPhoto.$invalid) {
        try {
          this.isLoading = true;
          await axios
            .post(
              `${this.apiUrl.clothPhoto}`,
              {
                cloth_id: this.clothPhoto.cloth_id,
                image: this.clothPhoto.image,
              },
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(async () => {
              this.isLoading = false;
              await this.getDetail();
              this.$store.dispatch(
                "notification/success",
                "Data berhasil disimpan"
              );
            });
        } catch (error) {
          this.isLoading = false;
          if (process.env.NODE_ENV !== "production") {
            console.log(error);
          }
          this.$store.dispatch("notification/error", error);
        }
      }
    },
    async onSubmitColorPhoto() {
      try {
        this.isLoading = true;
        await axios
          .post(
            `${this.apiUrl.clothColors}/${this.colorPhoto.id_color}`,
            {
              _method: "PATCH",
              image: this.colorPhoto.image,
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(async () => {
            this.isLoading = false;
            await this.getDetail();
            this.$store.dispatch(
              "notification/success",
              "Data berhasil disimpan"
            );
          });
      } catch (error) {
        this.isLoading = false;
        if (process.env.NODE_ENV !== "production") {
          console.log(error);
        }
        this.$store.dispatch("notification/error", error);
      }
    },
    returnBack() {
      return this.$router.go(-1);
    },

    async onSubmitEditSize() {
      try {
        const updateObject = {
          stock: Number(this.spesificStock),
          min_stock: Number(this.spesificMinStock),
          cloth_size_prices: this.spesificPrices.map((price) => {
            return {
              id: price.id,
              price: Number(price.price),
            };
          }),
        };

        this.isLoadingButton = true;
        await axios
          .patch(
            `${this.apiUrl.clothSizes}/${this.spesificIdSize}`,
            updateObject
          )
          .then(async () => {
            this.isLoadingButton = false;
            await this.getDetail();
            this.hideModalSizeEdit();
            this.$store.dispatch(
              "notification/success",
              "Data berhasil diupdate"
            );
          });
      } catch (error) {
        this.isLoadingButton = false;
        if (process.env.NODE_ENV !== "production") {
          console.log(error);
        }
        this.$store.dispatch("notification/error", error);
      }
    },
    async onDeleteSize(clothSizeId) {
      try {
        this.isLoadingButton = true;
        await axios
          .delete(`${this.apiUrl.clothSizes}/` + clothSizeId)
          .then(async () => {
            this.isLoadingButton = false;
            await this.getDetail();
            this.hideModalSizeEdit();
            this.$store.dispatch(
              "notification/success",
              "Data berhasil diupdate"
            );
          });
      } catch (error) {
        this.isLoadingButton = false;
        if (process.env.NODE_ENV !== "production") {
          console.log(error);
        }
        this.$store.dispatch("notification/error", error);
      }
    },
    deletedClothColor(clothColorId) {
      this.$http
        .delete(this.apiUrl.clothColors + "/" + clothColorId)
        .then(() => {
          this.isLoading = false;
          this.$store.dispatch(
            "notification/success",
            "Warna List Pakaian Berhasil Dihapus."
          );
          this.getDetail();
        })
        .catch((error) => {
          this.isLoading = false;
          this.$store.dispatch("notification/error", error);
        });
    },
    handleGetColorId(colorId, clothSizeId) {
      this.colorId = colorId;
      this.clothSizeId = clothSizeId;
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="$route.name" :items="items" />
    <div v-if="!isLoading">
      <div v-if="setData && setData.cloth_category">
        <div
          class="w-100 rounded-3 d-flex justify-content-center align-items-center shadow"
          style="height: 230px; background-color: #757575;"
        >
          <b-img-lazy
            :src="
              selectedPhoto !== null
                ? selectedPhoto
                : setPhoto.length > 0
                ? setPhoto[0].image
                : imgDummy
            "
            width="300"
            class="h-100"
            alt="Image Not Found"
            style="object-fit: cover;"
          />
        </div>
        <div class="w-100 overflow-hidden">
          <b-row class="my-2">
            <div class="d-flex" style="overflow-x: auto;">
              <b-col
                cols="3"
                class="m-1"
                v-for="photo in setPhoto"
                :key="photo.id"
                @click="selectPhoto(photo.image)"
              >
                <b-img-lazy
                  :src="`${photo.image}`"
                  alt=""
                  srcset=""
                  width="80"
                  height="80"
                  class="rounded shadow"
                  style="object-fit: cover;"
                />
              </b-col>
              <b-col class="m-1">
                <button
                  class="btn border-dark add-detail-photo"
                  style="width: 80px;height: 80px;"
                  @click="showModalPhoto"
                >
                  <i class="uil uil-plus"></i>
                </button>
              </b-col>
            </div>
          </b-row>
        </div>
        <div class="w-100 d-flex flex-column">
          <span class="fs-16-custom mt-2">
            <a :href="'#/pengguna/' + setData.created_by.id" target="_blank">
              Dibuat Oleh
              {{ setData.created_by ? setData.created_by.name : "-" }}
            </a>
          </span>
          <span class="fs-4 fw-bold text-dark mt-3">
            {{ setData.cloth_category ? setData.cloth_category.name : "-" }}
          </span>
          <span class="fs-16-custom fw-bold mt-2">
            Information
          </span>
          <b-row class="mt-1">
            <b-col cols="4">
              <span class="fw-bold text-secondary">
                Created Date
              </span>
            </b-col>
            <b-col>
              <span class="fw-bold text-secondary"
                >:
                {{
                  this.setData.created_at
                    ? this.formattedDate(this.setData.created_at)
                    : "-"
                }}</span
              >
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="4">
              <span class="fw-bold text-secondary">
                Updated Date
              </span>
            </b-col>
            <b-col>
              <span class="fw-bold text-secondary"
                >:
                {{
                  this.setData.updated_at
                    ? this.formattedDate(this.setData.updated_at)
                    : "-"
                }}</span
              >
            </b-col>
          </b-row>
        </div>
      </div>
      <div
        v-if="!isLoading"
        class="border border-1 border-secondary mt-3 mb-3"
      ></div>
      <div class="custom-tab">
        <b-row align-h="between" class="align-items-center">
          <b-col class="d-flex justify-content-start align-items-center"
            ><span class="fw-bold fs-4">List Warna</span></b-col
          >
          <b-col class="text-right">
            <b-button
              type="button"
              class="btn btn-success mx-2 waves-effect waves-light fw-bold"
              @click="showModalColor"
              ><i class="bx bx-plus"></i
            ></b-button>
          </b-col>
        </b-row>
        <b-tabs
          nav-class="nav-tabs-custom"
          content-class="text-muted"
          class="nav-tabs mt-2"
          style="overflow: hidden;"
        >
          <b-tab
            lazy
            fade
            v-for="cloth_color in setData.cloth_colors"
            :key="cloth_color.id"
          >
            <template v-slot:title>
              <span
                class="position-relative d-flex"
                @click="handleGetColorId(cloth_color.color.id, cloth_color.id)"
              >
                {{ cloth_color.color ? cloth_color.color.name : "-" }}
                <span
                  :style="{
                    bottom: `${8}px`,
                    right: `${-30}px`,
                    background: `#${
                      cloth_color.color.code_hexa !== null
                        ? cloth_color.color.code_hexa
                        : ''
                    }`,
                  }"
                  class="position-absolute translate-middle p-2 border rounded-circle"
                >
                </span>
              </span>
            </template>
            <b-row>
              <b-row class="my-2">
                <b-col sm="3">
                  <b-row>
                    <b-col>
                      <span v-if="cloth_color.image" class="fw-bold fs-6"
                        >Photo Warna Pakaian</span
                      >
                      <span v-else class="fw-bold fs-6"
                        >Upload Photo Warna Pakaian</span
                      >
                    </b-col>
                    <b-col
                      class="text-end d-flex justify-content-center align-items-center"
                    >
                      <b-dropdown
                        id="dropdown-right"
                        right
                        text="Right align"
                        variant="white"
                        class="m-0 position-absolute fs-1"
                        style="right: -10px;"
                      >
                        <template #button-content>
                          <i
                            class="bx bx-dots-vertical-rounded"
                            style="font-size: 22px;"
                          ></i>
                        </template>
                        <b-dropdown-item
                          @click="deletedClothColor(cloth_color.id)"
                          ><i class="bx bx-trash"></i> Hapus
                          Warna</b-dropdown-item
                        >
                      </b-dropdown>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      v-if="cloth_color.image"
                      class="p-3 d-flex flex-column"
                    >
                      <div
                        class="add-photo-color edit-photo shadow"
                        :style="{
                          backgroundImage: `url(${apiUrl.baseUrl}/storage/${cloth_color.image})`,
                          border: 'none',
                          backgroundRepeat: 'no-repeat',
                        }"
                      >
                        <el-button
                          @click="showModalColorPhoto(cloth_color.id)"
                          icon="el-icon-edit"
                          class="edit-photo-color"
                        />
                      </div>
                    </b-col>
                    <b-col v-else class="p-3 d-flex flex-column">
                      <el-button
                        @click="showModalColorPhoto(cloth_color.id)"
                        icon="el-icon-plus"
                        class="add-photo-color"
                      />
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-row>
                    <span class="fw-bold fs-6">SKU</span>
                  </b-row>
                  <b-row>
                    <b-col class="p-3" v-if="!editing">
                      <div>
                        <span
                          @click="showEditForm(cloth_color.sku)"
                          class="fw-bold text-dark fs-3"
                          style="cursor: pointer"
                        >
                          {{ cloth_color.sku }}
                        </span>
                      </div>
                    </b-col>
                    <b-col class="p-3" v-else>
                      <div
                        class="d-flex justify-content-start align-items-start gap-2 mt-2"
                      >
                        <input
                          type="text"
                          class="form-control"
                          v-model="editSku"
                        />
                        <div class="d-flex">
                          <el-button
                            @click="
                              saveEditSku(cloth_color.id, cloth_color.sku)
                            "
                            type="primary"
                            icon="el-icon-check"
                          />
                          <el-button
                            @click="cancelEditSku"
                            type="danger"
                            icon="el-icon-close"
                          />
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-row>
            <b-row class="mt-3">
              <b-col class="d-flex justify-content-start align-items-center"
                ><span class="fw-bold fs-4">List Ukuran</span></b-col
              >
              <b-col class="text-end">
                <b-button
                  variant="success"
                  @click="showModalSize"
                  class="mx-2 mx-2 waves-effect waves-light fw-bold"
                  >Tambah Ukuran</b-button
                >
              </b-col>
            </b-row>
            <div class="border border-dark my-2"></div>
            <div
              class="py-2"
              v-for="cloth_size in cloth_color.cloth_sizes"
              :key="cloth_size.id"
            >
              <div class="row">
                <div class="col-md-6">
                  <div
                    class="card shadow-none border-end border-bottom border-primary"
                  >
                    <b-card-body>
                      <div
                        class="d-flex justify-content-between align-items-center"
                      >
                        <h5 class="text-center mb-3 fw-bold">
                          Ukuran {{ cloth_size.size.name }}
                        </h5>
                        <div>
                          <b-dropdown
                            id="dropdown-right"
                            right
                            text="Right align"
                            variant="white"
                            class="m-0 absolute fs-1"
                            style="bottom: 4px;"
                          >
                            <template #button-content>
                              <i
                                class="bx bx-dots-vertical-rounded"
                                style="font-size: 22px;"
                              ></i>
                            </template>
                            <b-dropdown-item
                              @click="showModalSizeEdit(cloth_size)"
                              ><i class="bx bx-edit"></i> Edit
                              Ukuran</b-dropdown-item
                            >
                            <b-dropdown-item
                              @click="onDeleteSize(cloth_size.id)"
                              ><i class="bx bx-trash"></i> Hapus
                              Ukuran</b-dropdown-item
                            >
                          </b-dropdown>
                        </div>
                      </div>
                      <ul class="activity-feed mb-0 ps-2">
                        <li class="feed-item">
                          <div class="feed-item-list">
                            <p class="text-muted mb-1">
                              Stock
                            </p>
                            <p class="fw-bold mb-1 ps-2">
                              {{ cloth_size.stock }}
                            </p>
                          </div>
                        </li>
                        <li class="feed-item">
                          <div class="feed-item-list">
                            <p class="text-muted mb-1">
                              Min Stock
                            </p>
                            <p class="fw-bold mb-1 ps-2">
                              {{ cloth_size.min_stock }}
                            </p>
                          </div>
                        </li>
                      </ul>
                      <ul
                        class="activity-feed mb-0 ps-2"
                        v-for="cloth_size_price in cloth_size.cloth_size_prices"
                        :key="cloth_size_price.id"
                      >
                        <li class="feed-item">
                          <div class="feed-item-list">
                            <p class="text-muted mb-1">
                              {{ cloth_size_price.cloth_price_type.name }}
                            </p>
                            <p class="fw-bold mb-1 ps-2">
                              {{ formattedPrice(cloth_size_price.price) }}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </b-card-body>
                  </div>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <template v-if="!isLoading">
      <!-- Modal add Cloth Photo -->
      <b-modal
        ref="modal-cloth-photo"
        size="lg"
        title="Upload Photo"
        title-class="font-18"
        hide-footer
        centered
      >
        <form
          enctype="multipart/form-data"
          @submit.prevent="onSubmitClothPhoto"
        >
          <label for="formFileUpload">Masukkan Photo</label>
          <b-form-file
            id="formFileUpload"
            v-model="clothPhoto.image"
            accept="image/*"
          ></b-form-file>
          <p v-if="clothPhotoErrors.$error" class="text-danger">
            Harap masukkan foto
          </p>
          <b-button type="submit" variant="primary" class="mt-3 text-center"
            >Simpan</b-button
          >
        </form>
      </b-modal>

      <!-- Modal Color Photo -->
      <b-modal
        ref="modal-color-photo"
        size="lg"
        title="Upload Photo"
        title-class="font-18"
        hide-footer
        centered
      >
        <form @submit.prevent="onSubmitColorPhoto()">
          <label for="formFileUpload">Masukkan Photo Warna Pakaian</label>
          <b-form-file
            v-model="colorPhoto.image"
            id="formFileUpload"
            accept="image/*"
          ></b-form-file>
          <b-button type="submit" variant="primary" class="mt-3 text-center"
            >Simpan</b-button
          >
        </form>
      </b-modal>

      <!-- Modal Cloth Color -->
      <b-modal
        size="xl"
        ref="modal-cloth-color"
        title="Tambah Warna"
        title-class="font-18"
        hide-footer
        centered
      >
        <div class="mx-3">
          <form enctype="multipart/form-data" @submit.prevent="onSubmitColor">
            <div id="parent">
              <b-row>
                <h5 class="fw-bold text-dark">
                  Filter Warna dan Ukuran Pakaian
                </h5>
                <b-col class="fw-bold"> Pilih Warna Pakaian </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select-all-colors"
                      name="select-all-colors"
                      value="1"
                      v-model="selectAllColors"
                    />
                    <label for="select-all-colors" class="form-check-label"
                      >Pilih Semua Warna</label
                    >
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col class="d-flex flex-wrap gap-2">
                  <div
                    class="form-check form-check-inline d-flex justify-content-start align-items-center gap-2 color-wrap"
                    v-for="(color, index) in setColor"
                    :key="color.id"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :id="`checkbox-${color.id}-${index}`"
                      v-model="checkedColors"
                      :name="`checkbox-${color.id}-${index}`"
                      :value="color.id"
                    />
                    <span
                      class="color-indicator"
                      :style="{ background: `#${color.code_hexa}` }"
                    ></span>
                    <label
                      class="form-check-label"
                      :for="`checkbox-${color.id}-${index}`"
                      ><span class="fs-6 fw-bold">{{ color.name }}</span></label
                    >
                  </div>
                  <p v-if="checkedColorErrors.$error" class="text-danger">
                    Harap pilih warna pakaian, minimal 1
                  </p>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col class="fw-bold">Pilih Ukuran Pakaian</b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select-all-sizes"
                      name="select-all-sizes"
                      value="1"
                      v-model="selectAllSizes"
                    />
                    <label for="select-all-sizes" class="form-check-label"
                      >Pilih Semua Ukuran</label
                    >
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <div
                    class="form-check form-check-inline"
                    v-for="(size, index) in setSize"
                    :key="size.id"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :id="`checkbox-${size.id}-${index}`"
                      v-model="checkedSizes"
                      :name="`checkbox-${size.id}-${index}`"
                      :value="size.id"
                    />
                    <label
                      class="form-check-label"
                      :for="`checkbox-${size.id}-${index}`"
                    >
                      <span
                        class="bg-success px-1 fs-6 rounded fw-bold text-white"
                        >{{ size.name }}</span
                      >
                    </label>
                  </div>
                  <p v-if="checkedSizeErrors.$error" class="text-danger">
                    Harap pilih ukuran pakaian, minimal 1
                  </p>
                </b-col>
              </b-row>
              <div class="border-bottom border-dark my-4"></div>
              <div
                v-for="(cloth_color, clothColorIndex) in setFields.cloth_color"
                :key="cloth_color.id"
              >
                <b-row>
                  <template v-for="color in setColor">
                    <div
                      class="fs-4 fw-bold pb-2 d-flex align-items-center gap-2"
                      v-if="color.id === cloth_color.color_id"
                      :key="color.id"
                    >
                      <span
                        class="indicator-color"
                        :style="{ background: `#${color.code_hexa}` }"
                      ></span>
                      - {{ color.name }}
                    </div>
                  </template>
                  <b-col md="4" class="pb-3">
                    <b-form-group
                      label="SKU"
                      :label-for="`input-${cloth_color.id}-sku`"
                      class="mb-0"
                    >
                      <b-form-input
                        :id="`input-${cloth_color.id}-sku`"
                        v-model="cloth_color.sku"
                        @change="
                          updateSku(cloth_color.color_id, cloth_color.sku)
                        "
                        placeholder="Masukkan Kode SKU"
                      ></b-form-input>
                    </b-form-group>
                    <p
                      v-if="
                        checkedColors.length > 0 &&
                          clothColorErrors[clothColorIndex].sku.$error
                      "
                      class="text-danger"
                    >
                      Harap masukkan SKU
                    </p>
                  </b-col>
                </b-row>
                <div
                  v-for="(cloth_size, clothSizeIndex) in cloth_color.cloth_size"
                  :key="cloth_size.id"
                  class="my-2"
                >
                  <b-row>
                    <template v-for="size in setSize">
                      <span
                        class="fs-5 fw-bold pb-2"
                        v-if="size.id === cloth_size.size_id"
                        :key="size.id"
                        >Ukuran -
                        <span
                          class="bg-success px-1 fs-6 rounded fw-bold text-white"
                        >
                          {{ size.name }}
                        </span>
                      </span>
                    </template>
                    <b-col class="pb-3 pt-1" md="4">
                      <b-form-group
                        label="Stok"
                        :label-for="`input-${cloth_size.id}-stock`"
                        class="mb-0"
                      >
                        <b-form-input
                          :id="`input-${cloth_size.id}-stock`"
                          v-model="cloth_size.stock"
                          placeholder="Masukkan Stok Pakaian"
                        ></b-form-input>
                      </b-form-group>
                      <p
                        v-if="
                          checkedSizes.length > 0 &&
                            clothColorErrors[clothColorIndex].cloth_size.$each[
                              clothSizeIndex
                            ].stock.$error
                        "
                        class="text-danger"
                      >
                        Harap masukkan stok pakaian
                      </p>
                    </b-col>

                    <b-col class="pb-3 pt-1" md="4">
                      <b-form-group
                        label="Min-Stock"
                        :label-for="`input-${cloth_size.id}-min_stock`"
                        class="mb-0"
                      >
                        <b-form-input
                          :id="`input-${cloth_size.id}-min_stock`"
                          v-model="cloth_size.min_stock"
                          placeholder="Masukkan Minimal Stok Pakaian"
                        ></b-form-input>
                        <p
                          v-if="
                            checkedSizes2.length > 0 &&
                              clothSizeErrors[clothSizeIndex].stock.$error
                          "
                          class="text-danger"
                        >
                          Harap masukkan minimal stok pakaian
                        </p>
                      </b-form-group>
                    </b-col>

                    <b-col
                      md="4"
                      class="pb-3 pt-1"
                      v-for="(cloth_price,
                      clothPriceIndex) in cloth_size.cloth_price"
                      :key="cloth_price.id"
                    >
                      <template v-for="priceType in setClothPriceType">
                        <b-form-group
                          v-if="
                            priceType.id === cloth_price.cloth_price_type_id
                          "
                          :key="priceType.id"
                          :label="priceType.name"
                          :label-for="
                            `input-${cloth_price.id}-${priceType.name}`
                          "
                          class="mb-0"
                        >
                          <b-form-input
                            :id="`input-${cloth_price.id}-${priceType.name}`"
                            type="number"
                            v-model="cloth_price.price"
                            :placeholder="`Masukkan ${priceType.name}`"
                          ></b-form-input>
                          <p
                            v-if="
                              checkedSizes.length > 0 &&
                                clothColorErrors[clothColorIndex].cloth_size
                                  .$each[clothSizeIndex].cloth_price.$each[
                                  clothPriceIndex
                                ].price.$error
                            "
                            class="text-danger"
                          >
                            {{
                              `Harap masukkan ${priceType.name.toLowerCase()}`
                            }}
                          </p>
                        </b-form-group>
                      </template>
                    </b-col>
                  </b-row>
                </div>
                <div class="border-bottom border-dark my-4"></div>
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <b-button type="submit" variant="primary" class="mt-3 float-left"
                >Simpan</b-button
              >
              <b-button class="mt-3" block @click="hideModalColor()"
                >Batal</b-button
              >
            </div>
          </form>
        </div>
      </b-modal>

      <!-- Modal Cloth Size -->
      <b-modal
        size="xl"
        ref="modal-cloth-size"
        title="Tambah Ukuran"
        title-class="font-18"
        hide-footer
        centered
      >
        <div class="mx-3">
          <form enctype="multipart/form-data" @submit.prevent="onSubmitSize">
            <div id="parent">
              <h5 class="fw-bold text-dark">Filter Ukuran Pakaian</h5>
              <b-row>
                <b-col class="fw-bold"> Pilih Ukuran Pakaian </b-col>
              </b-row>
              <b-row class="mt-3">
                <b-col>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select-all-sizes"
                      name="select-all-sizes"
                      value="1"
                      v-model="selectAllSizes2"
                    />
                    <label for="select-all-sizes" class="form-check-label"
                      >Pilih Semua Ukuran</label
                    >
                  </div>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <div
                    class="form-check form-check-inline"
                    v-for="(size, index) in setSize"
                    :key="size.id"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :id="`checkbox-${size.id}-${index}`"
                      v-model="checkedSizes2"
                      :name="`checkbox-${size.id}-${index}`"
                      :value="size.id"
                    />
                    <label
                      class="form-check-label"
                      :for="`checkbox-${size.id}-${index}`"
                      ><span
                        class="bg-success px-1 fs-6 rounded fw-bold text-white"
                        >{{ size.name }}</span
                      ></label
                    >
                  </div>
                  <p v-if="checkedSize2Errors.$error" class="text-danger">
                    Harap pilih warna ukuran, minimal 1
                  </p>
                </b-col>
              </b-row>
              <div class="border-bottom border-dark my-4"></div>
              <div
                v-for="(cloth_size, clothSizeIndex) in setFields2.cloth_size"
                :key="cloth_size.id"
                class="my-2"
              >
                <b-row>
                  <template v-for="size in setSize">
                    <span
                      class="fs-5 fw-bold pb-2"
                      v-if="size.id === cloth_size.size_id"
                      :key="size.id"
                      >Ukuran -
                      <span
                        class="bg-success px-1 fs-6 rounded fw-bold text-white"
                      >
                        {{ size.name }}
                      </span>
                    </span>
                  </template>
                  <b-col class="pb-3 pt-1" md="4">
                    <b-form-group
                      label="Stok"
                      :label-for="`input-${cloth_size.id}-stock`"
                      class="mb-0"
                    >
                      <b-form-input
                        :id="`input-${cloth_size.id}-stock`"
                        v-model="cloth_size.stock"
                        placeholder="Masukkan Stok Pakaian"
                      ></b-form-input>
                      <p
                        v-if="
                          checkedSizes2.length > 0 &&
                            clothSizeErrors[clothSizeIndex].stock.$error
                        "
                        class="text-danger"
                      >
                        Harap masukkan stok pakaian
                      </p>
                    </b-form-group>
                  </b-col>

                  <b-col class="pb-3 pt-1" md="4">
                    <b-form-group
                      label="Min-Stock"
                      :label-for="`input-${cloth_size.id}-min_stock`"
                      class="mb-0"
                    >
                      <b-form-input
                        :id="`input-${cloth_size.id}-min_stock`"
                        v-model="cloth_size.min_stock"
                        placeholder="Masukkan Minimal Stok Pakaian"
                      ></b-form-input>
                      <p
                        v-if="
                          checkedSizes2.length > 0 &&
                            clothSizeErrors[clothSizeIndex].stock.$error
                        "
                        class="text-danger"
                      >
                        Harap masukkan minimal stok pakaian
                      </p>
                    </b-form-group>
                  </b-col>

                  <b-col
                    md="4"
                    class="pb-3 pt-1"
                    v-for="(cloth_price,
                    clothPriceIndex) in cloth_size.cloth_price"
                    :key="cloth_price.id"
                  >
                    <template v-for="priceType in setClothPriceType">
                      <b-form-group
                        v-if="priceType.id === cloth_price.cloth_price_type_id"
                        :key="priceType.id"
                        :label="priceType.name"
                        :label-for="`input-${cloth_price.id}-${priceType.name}`"
                        class="mb-0"
                      >
                        <b-form-input
                          :id="`input-${cloth_price.id}-${priceType.name}`"
                          type="number"
                          v-model="cloth_price.price"
                          :placeholder="`Masukkan ${priceType.name}`"
                        ></b-form-input>
                        <p
                          v-if="
                            checkedSizes2.length > 0 &&
                              clothSizeErrors[clothSizeIndex].cloth_price.$each[
                                clothPriceIndex
                              ].price.$error
                          "
                          class="text-danger"
                        >
                          {{ `Harap masukkan ${priceType.name.toLowerCase()}` }}
                        </p>
                      </b-form-group>
                    </template>
                  </b-col>
                </b-row>
                <div class="border-bottom border-dark my-4"></div>
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <b-button type="submit" variant="primary" class="mt-3 float-left"
                >Simpan</b-button
              >
              <b-button class="mt-3" block @click="hideModalSize()"
                >Batal</b-button
              >
            </div>
          </form>
        </div>
      </b-modal>

      <!-- Modal Cloth Size Edit -->
      <b-modal
        size="md"
        ref="modal-cloth-size-edit"
        title="Edit Harga Ukuran"
        title-class="font-18"
        hide-footer
        centered
      >
        <form @submit.prevent="onSubmitEditSize" class="mx-3">
          <b-row>
            <b-col class="col-md-4 my-2" sm="6">
              <label :for="'price-' + spesificStock"> Stock </label>
              <input
                class="form-control"
                type="number"
                :placeholder="spesificStock"
                :id="'price-' + spesificStock"
                name="select-all-colors"
                v-model="spesificStock"
              />
            </b-col>
            <b-col class="col-md-4 my-2" sm="6">
              <label :for="'price-' + spesificMinStock"> Min-Stock </label>
              <input
                class="form-control"
                type="number"
                :placeholder="spesificMinStock"
                :id="'price-' + spesificMinStock"
                name="select-all-colors"
                v-model="spesificMinStock"
              />
            </b-col>
            <b-col
              v-for="prices in spesificPrices"
              :key="prices.id"
              class="col-md-4 my-2"
              sm="6"
            >
              <label :for="'price-' + prices.cloth_price_type.id">{{
                prices.cloth_price_type.name
              }}</label>
              <input
                class="form-control"
                type="number"
                :placeholder="prices.price"
                :id="'price-' + prices.cloth_price_type.id"
                v-model="prices.price"
              />
            </b-col>
          </b-row>
          <div class="border-bottom border-dark my-4"></div>
          <b-row>
            <b-col class="col-md-6">
              <b-button
                type="submit"
                variant="primary"
                :disabled="isLoadingButton"
                class="w-sm"
                ><b-spinner
                  style="width: 1rem; height: 1rem; margin-right: 5px"
                  label="Loading..."
                  v-if="isLoadingButton"
                ></b-spinner>
                Update</b-button
              >
            </b-col>
            <b-col class="col-md-6 text-end">
              <b-button @click="hideModalSizeEdit()">Batal</b-button>
            </b-col>
          </b-row>
        </form>
      </b-modal>
    </template>

    <div v-else>
      <skeletonLoading width="100%" height="200px" class="rounded-3" />
      <div class="w-100">
        <b-row class="my-2">
          <b-col cols="3" v-for="(imgSmal, index) in smallImg" :key="index">
            <skeletonLoading width="75px" height="80px" class="rounded-3" />
          </b-col>
        </b-row>
      </div>
      <div class="w-100 mt-4">
        <b-row
          class="my-3"
          cols="3"
          v-for="(imgSmal, index) in smallImg"
          :key="index"
        >
          <b-col>
            <skeletonLoading width="270px" height="20px" class="rounded-3" />
          </b-col>
        </b-row>
      </div>
      <div class="w-100 mt-3 d-flex justify-content-between">
        <skeletonLoading
          v-for="(imgSmal, index) in smallImg"
          :key="index"
          width="80px"
          height="30px"
          class="rounded-3"
        />
      </div>
    </div>
  </Layout>
</template>

<style>
.nav {
  gap: 15px;
}
</style>

<style>
.activity-feed .feed-item:last-child {
  border-color: #ebeef5 !important;
}
.border-custom {
  border-color: #ebeef5 !important;
  /* display: block; */
  overflow-x: auto;
  white-space: nowrap;
}

.nav-tabs {
  overflow-x: auto;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding: 5px;
}
</style>
