<script>
export default {
  props: {
    minStock: {
      type: Number,
      default: false
    }
  },
  data() {
    return {
      apiUrl: {
        cloth: process.env.VUE_APP_API_BASE_URL + 'clothes'
      }
    }
  },
  methods: {
    submitMinStock () {
      this.$http.patch(
        `${this.apiUrl.cloth}/${this.$route.params.id}/min-stock`,
        {
          min_stock: this.minStock,
        },
      )
      .then(() => {
        this.closeModal()
        this.$emit('closeModalComponent')
        this.$store.dispatch(
          "notification/success",
          "Data berhasil disimpan"
        );
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          console.log(error);
        }
        this.$store.dispatch("notification/error", error);
      });
    },
    showModalEditMinStock () {
      this.$refs["modal-edit-min-stock"].show();
    },
    closeModal () {
      this.$refs["modal-edit-min-stock"].hide();
    }
  }
}
</script>

<template>
  <div>
    <b-button
      variant="info"
      type="button"
      class="btn mx-2 waves-effect waves-light fw-bold"
      @click="showModalEditMinStock"
    >
      Edit Min Stok
    </b-button>
    <b-modal id="modal-edit-min-stock" centered modal-header-close ref="modal-edit-min-stock" hide-footer>
      <template #modal-title>
        Edit Min Stock
      </template>
      <form @submit.prevent="submitMinStock">
        <div class="d-block">
          <b-form-group
            id="fieldset-1"
            label="Min. Stock"
            label-for="input-1"
          >
            <b-form-input type="number" id="input-1" v-model="minStock"></b-form-input>
          </b-form-group>
        </div>
        <div class="d-flex justify-content-between">
          <b-button variant="primary" type="submit" class="mt-3" block >Submit</b-button>
          <b-button class="mt-3" block @click="closeModal">Batal</b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>