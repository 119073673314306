<script>
export default {
  props: {
    dataClothSizes: {
      type: [Array, Object],
      required: true,
    },
    dataSizes: {
      type: [Array, Object],
      required: true,
    }
  },
  data() {
    return {
      apiUrl: {
        cloth: process.env.VUE_APP_API_BASE_URL + 'clothes'
      }
    }
  },
  // watch: {
  //   dataSizes: function(newVal) {
  //     const defaultClothPrices = this.dataClothSizes[0].cloth_size_prices;
  
  //     this.dataSizes.forEach(data => {
  //       data.price_modal = null
  //       data.price_tiktok = null
  //       data.price_shopee = null
  //       data.price_pelanggan = null
  //       data.price_reseller = null
  //       data.price_distributor = null
  //     });
  //   }
  // },
  methods: {
    submitAddSize () {
      let submit_data = this.dataSizes.filter(datasize => datasize.selected == 1)
      // let submit_data = []
      // this.dataClothSizes.forEach((clothSize, index) => {
      //   submit_data.push({
      //     size_id: clothSize.size.id,
      //     cloth_size_prices: []
      //   })
      //   clothSize.cloth_size_prices.forEach(clothPrice => {
      //     submit_data[index].cloth_size_prices.push({
      //       cloth_price_type_id: clothPrice.cloth_price_type_id,
      //       price: clothPrice.price
      //     })
      //   })
      // })
      console.log(submit_data)
      this.$http.post(
        `${this.apiUrl.cloth}/${this.$route.params.id}/size`,
        {
          cloth_sizes: submit_data,
        },
      )
      .then(() => {
        this.closeModal()
        this.$emit('closeModalComponent')
        this.$store.dispatch(
          "notification/success",
          "Data berhasil disimpan"
        );
      })
      .catch((error) => {
        if (process.env.NODE_ENV !== "production") {
          console.log(error);
        }
        this.$store.dispatch("notification/error", error);
      });
    },
    showModal () {
      console.log('===')
      this.dataSizes.forEach(data => {
        data.price_modal = null
        data.price_tiktok = null
        data.price_shopee = null
        data.price_pelanggan = null
        data.price_reseller = null
        data.price_distributor = null
      });
      this.$refs["modal-add-size"].show();
    },
    closeModal () {
      this.$refs["modal-add-size"].hide();
    }
  }
}
</script>

<template>
  <div>
    <b-button
      variant="secondary"
      type="button"
      class="btn mx-2 waves-effect waves-light fw-bold"
      @click="showModal"
    >
      Tambah Ukuran
    </b-button>
    <b-modal id="modal-add-size" size="xl" centered modal-header-close ref="modal-add-size" hide-footer>
      <template #modal-title>
        Tambah Ukuran
      </template>
      <form @submit.prevent="submitAddSize">
        <table class="table table-striped table-bordered">
          <thead>
            <th></th>
            <th>Ukuran</th>
            <th class="text-end" v-for="dataPrice in dataClothSizes[0].cloth_size_prices">{{ dataPrice.cloth_price_type.name }}</th>
          </thead>
          <tbody>
            <tr v-for="(dataSize, indexDataSize) in dataSizes">
              <td>
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="dataSize.selected"
                  name="checkbox-1"
                  value="1"
                  unchecked-value="0"
                >
                </b-form-checkbox>
              </td>
              <td>{{ dataSize.name }}</td>
              <td class="text-end p-0">
                <b-form-input type="number" class="text-end" v-model="dataSize.price_modal" placeholder="0" :id="'input-price_modal-1'+indexDataSize"  :name="'input-price_modal-1'+indexDataSize" style="text-align: right;"></b-form-input>
              </td>
              <td class="text-end p-0">
                <b-form-input type="number" class="text-end" v-model="dataSize.price_tiktok" placeholder="0" :id="'input-price_tiktok-1'+indexDataSize"  :name="'input-price_tiktok-1'+indexDataSize" style="text-align: right;"></b-form-input>
              </td>
              <td class="text-end p-0">
                <b-form-input type="number" class="text-end" v-model="dataSize.price_shopee" placeholder="0" :id="'input-price_shopee-1'+indexDataSize"  :name="'input-price_shopee-1'+indexDataSize" style="text-align: right;"></b-form-input>
              </td>
              <td class="text-end p-0">
                <b-form-input type="number" class="text-end" v-model="dataSize.price_pelanggan" placeholder="0" :id="'input-price_pelanggan-1'+indexDataSize"  :name="'input-price_pelanggan-1'+indexDataSize" style="text-align: right;"></b-form-input>
              </td>
              <td class="text-end p-0">
                <b-form-input type="number" class="text-end" v-model="dataSize.price_reseller" placeholder="0" :id="'input-price_reseller-1'+indexDataSize"  :name="'input-price_reseller-1'+indexDataSize" style="text-align: right;"></b-form-input>
              </td>
              <td class="text-end p-0">
                <b-form-input type="number" class="text-end" v-model="dataSize.price_distributor" placeholder="0" :id="'input-price_distributor-1'+indexDataSize"  :name="'input-price_distributor-1'+indexDataSize" style="text-align: right;"></b-form-input>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <div class="d-block">
          <b-form-group
            id="fieldset-1"
            label="Min. Stock"
            label-for="input-1"
          >
            <b-form-input type="number" id="input-1" v-model="minStock"></b-form-input>
          </b-form-group>
        </div> -->
        <div class="d-flex justify-content-between">
          <b-button variant="primary" type="submit" class="mt-3" block >Submit</b-button>
          <b-button class="mt-3" block @click="closeModal">Batal</b-button>
        </div>
      </form>
    </b-modal>
  </div>
</template>