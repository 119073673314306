<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import detailM from "./detail-m.vue";
import modalEditMinStock from "./components/modalEditMinStock.vue";
import modalEditPrice from "./components/modalEditPrice.vue";
import modalAddSize from "./components/modalAddSize.vue";
import axios from "axios";
import { convertDateTime, convertPrice, detectScreen } from "@/_helpers/index";
import { BFormFile } from "bootstrap-vue";
import customTable from "@/components/paginate-table.vue";
import {
  required,
  email,
  minLength,
  sameAs,
  maxLength,
  minValue,
  maxValue,
  numeric,
  url,
  alphaNum,
} from "vuelidate/lib/validators";
import skeletonLoading from "@/components/skeleton";

/**
 * Starter page
 */
export default {
  components: {
    Layout,
    PageHeader,
    BFormFile,
    skeletonLoading,
    modalEditMinStock,
    modalEditPrice,
    modalAddSize,
    customTable,
    detailM,
  },
  page: {
    title: "Detail Produk",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Produk",
          href: "/produk",
        },
        {
          text: this.$route.name,
          active: true,
        },
      ],
      columns: [
        {
          prop: "cloth_id",
          label: "Pakaian",
          sortable: true,
          center: true,
          cloth_id: true,
          width: "300",
        },
        {
          prop: "created_by",
          label: "Dibuat Oleh",
          sortable: true,
          center: true,
          created_history: true,
          width: "200",
        },
        {
          prop: "updated_by",
          label: "Diperbarui Oleh",
          sortable: true,
          center: true,
          updated_history: true,
          width: "200",
        },
        {
          prop: "referral_id",
          label: "Id Referral",
          sortable: true,
          center: true,
          width: "300",
        },
        {
          prop: "referral",
          label: "Referral",
          sortable: true,
          center: true,
          width: "200",
        },
        {
          prop: "note",
          label: "Catatan",
          sortable: true,
          center: true,
          width: "300",
        },
        {
          prop: "created_at",
          label: "Tgl Dibuat",
          sortable: true,
          center: true,
          date: true,
          width: "200",
        },
        {
          prop: "updated_at",
          label: "Tgl Diubah",
          sortable: true,
          center: true,
          date: true,
          width: "200",
        },
      ],
      isLoading: false,
      setData: [],
      apiUrl: {
        baseUrl: process.env.VUE_APP_BASE_URL,
        color: process.env.VUE_APP_API_BASE_URL + "colors",
        size: process.env.VUE_APP_API_BASE_URL + "sizes",
        cloth: process.env.VUE_APP_API_BASE_URL + "clothes",
        clothPhoto: process.env.VUE_APP_API_BASE_URL + "cloth-photos",
        clothPriceType: process.env.VUE_APP_API_BASE_URL + "cloth-price-types",
        clothColors: process.env.VUE_APP_API_BASE_URL + "cloth-colors",
        clothSizes: process.env.VUE_APP_API_BASE_URL + "cloth-sizes",
        clothHistory: process.env.VUE_APP_API_BASE_URL + "cloth-history-logs",
      },
      setColor: [],
      setSize: [],
      setSizeModal: [],
      setClothPriceType: [],
      selectAllColors: false,
      selectAllSizes: false,
      checkedColors: [],
      checkedSizes: [],
      setFields: {
        cloth_color: [],
      },
      selectAllSizes2: false,
      checkedSizes2: [],
      setFields2: {
        cloth_size: [],
      },
      setPhoto: [],
      clothPhoto: {
        image: null,
        cloth_id: this.$route.params.id,
      },
      colorPhoto: {
        id_color: "",
        image: null,
      },
      isDisabled: false,
      sku: "SKU Awal",
      editing: false,
      editSku: "",
      isLoadingButton: false,
      spesificIdSize: null,
      spesificPrices: null,
      spesificStock: 0,
      spesificMinStock: 0,
      spesificUpdatePrice: {
        stock: null,
        cloth_size_prices: [],
      },
      screenWidth: detectScreen(),
      clothSizeId: null,
      componentKey: 0,
    };
  },
  watch: {
    spesificPrices: {
      handler(newPrices) {
        this.spesificUpdatePrice.cloth_size_prices = newPrices.map((price) => {
          return {
            id: price.id,
            price: Number(price.price),
          };
        });
        this.spesificUpdatePrice.stock = Number(this.spesificStock);
        this.spesificUpdatePrice.stock = Number(this.spesificStock);
      },
      deep: true,
    },
    setData: {
      deep: true,
      handler() {},
    },
    checkedColors: function(newVal) {
      this.setFields.cloth_color = newVal.map((colorId) => {
        let sku = this.skus !== undefined ? this.skus[colorId] : null;
        if (sku === undefined) {
          sku = null;
        }
        let existingColor = this.setFields.cloth_color.find(
          (color) => color.color_id === colorId
        );
        let cloth_size = existingColor
          ? existingColor.cloth_size
          : this.checkedSizes.map((sizeId) => ({
              size_id: sizeId,
              stock: null,
              cloth_price: this.setClothPriceType.map((priceType) => ({
                cloth_price_type_id: priceType.id,
              })),
            }));

        return {
          color_id: colorId,
          cloth_size: cloth_size,
        };
      });
    },
    checkedSizes(newVal) {
      this.setFields.cloth_color.forEach((color) => {
        color.cloth_size = newVal.map((sizeId) => {
          let existingSize = color.cloth_size.find(
            (size) => size.size_id === sizeId
          );
          let stock = existingSize ? existingSize.stock : null;
          let cloth_price = this.setClothPriceType.map((priceType) => ({
            cloth_price_type_id: priceType.id,
            price: existingSize,
            // ? existingSize.cloth_price.find(
            //     (price) => price.cloth_price_type_id === priceType.id
            //   )?.price
            // : null,
          }));

          return {
            size_id: sizeId,
            stock: 0,
            cloth_price: cloth_price,
          };
        });
      });
    },
    checkedSizes2(newVal) {
      this.setFields2.cloth_size = newVal.map((sizeId) => {
        let existingSize = this.setFields2.cloth_size.find(
          (size) => size.size_id === sizeId
        );
        let stock = existingSize ? existingSize.stock : null;
        let cloth_price = this.setClothPriceType.map((priceType) => ({
          cloth_price_type_id: priceType.id,
          price: existingSize
            ? existingSize.cloth_price.find(
                (price) => price.cloth_price_type_id === priceType.id
              )?.price
            : null,
        }));

        return {
          size_id: sizeId,
          stock: stock,
          cloth_price: cloth_price,
        };
      });
    },
    selectAllColors(newVal) {
      if (newVal) {
        this.checkedColors = this.setColor.map((color) => color.id);
      } else {
        this.checkedColors = [];
      }
    },
    selectAllSizes(newVal) {
      if (newVal) {
        this.checkedSizes = this.setSize.map((size) => size.id);
      } else {
        this.checkedSizes = [];
      }
    },
    selectAllSizes2(newVal) {
      if (newVal) {
        this.checkedSizes2 = this.setSize.map((size) => size.id);
      } else {
        this.checkedSizes2 = [];
      }
    },
  },
  created() {},
  mounted() {
    this.checkData();
    this.getDetail();
    // this.getColor();
    // this.getSize();
    this.getClothPriceType();
    this.getClothPhoto();
    this.checkFunction();
  },
  computed: {
    checkedColorErrors() {
      return this.$v.checkedColors;
    },
    clothColorErrors() {
      return this.$v.setFields.cloth_color.$each;
    },
    clothSizeErrors() {
      return this.$v.setFields2.cloth_size.$each;
    },
    // checkedSizeErrors() {
    //   return this.$v.checkedSizes;
    // },
    checkedSize2Errors() {
      return this.$v.checkedSizes2;
    },
    clothPhotoErrors() {
      return this.$v.clothPhoto.image;
    },
  },
  validations: {
    // setFields: {
    //   cloth_color: {
    //     $each: {
    //       sku: {
    //         required,
    //       },
    //       cloth_size: {
    //         $each: {
    //           stock: {
    //             required,
    //           },
    //           cloth_price: {
    //             $each: {
    //               price: {
    //                 required,
    //               },
    //             },
    //           },
    //         },
    //       },
    //     },
    //   },
    // },
    // setFields2: {
    //   cloth_size: {
    //     $each: {
    //       stock: {
    //         required,
    //       },
    //       cloth_price: {
    //         $each: {
    //           price: {
    //             required,
    //           },
    //         },
    //       },
    //     },
    //   },
    // },
    checkedColors: {
      required,
      minLength: minLength(1),
    },
    checkedSizes: {
      required,
      minLength: minLength(1),
    },
    checkedSizes2: {
      required,
      minLength: minLength(1),
    },
    clothPhoto: {
      image: {
        required,
      },
    },
  },
  methods: {
    showEditForm(sku) {
      this.editing = true;
      this.editSku = sku;
    },

    onScreenResize() {
      this.screenWidth = detectScreen();
    },

    async saveEditSku(id_color) {
      try {
        // this.isLoading = true;
        await axios
          .patch(
            `${this.apiUrl.clothColors}/${id_color}`,
            { sku_code: this.editSku },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then(async () => {
            this.isLoading = false;
            this.editing = false;
            await this.getDetail();
            this.$notify({
              message: "SKU Berhasil Diubah",
              position: "bottom-right",
              type: "success",
              duration: 5000,
            });
          });
      } catch (error) {
        this.isLoading = false;
        if (process.env.NODE_ENV !== "production") {
          console.log(error);
        }
        this.$store.dispatch("notification/error", error);
        this.$notify.error({
          message: this.notification.message,
          position: "bottom-right",
          duration: 5000,
        });
      }
    },

    cancelEditSku() {
      this.editing = false;
    },

    async checkData() {
      try {
        // await this.getDetail();
        await this.getColor();
        await this.getSize();
        this.setColor = this.setColor.filter((color) => {
          return !this.setData.cloth_colors.some((clothColor) => {
            return clothColor.color.name === color.name;
          });
        });
        return (this.setSizeModal = this.setSizeModal.filter((item2) => {
          const sizeName = item2.name;
          const isSizeNameInData1 = this.setData.cloth_colors.some((color) => {
            return color.cloth_sizes.some((size) => {
              return size.size.name === sizeName;
            });
          });
          return !isSizeNameInData1;
        }));
      } catch (error) {
        console.error(error);
      }
    },

    async getDetail() {
      try {
        this.isLoading = true;
        await axios
          .get(`${this.apiUrl.cloth}/${this.$route.params.id}`)
          .then((response) => {
            this.setData = response.data;
            this.isLoading = false;
          });
      } catch (error) {
        console.error(error);
        if (error.response.status == 404) {
          this.$router.push("/404-notfound");
        }
      }
    },

    deletedClothColor(clothColorId) {
      this.$swal
        .fire({
          title: "Apakah Kamu Yakin ?",
          text: "Data tidak dapat dikembalikan!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Ya, Hapus!",
        })
        .then((result) => {
          if (result.value) {
            this.$http
              .delete(this.apiUrl.clothColors + "/" + clothColorId)
              .then(() => {
                this.isLoading = false;
                this.$store.dispatch(
                  "notification/success",
                  "Warna List Pakaian Berhasil Dihapus."
                );
                this.getDetail();
              })
              .catch((error) => {
                this.isLoading = false;
                this.$store.dispatch("notification/error", error);
              });
          }
        });
    },

    deletedClothPhoto(clothPhotoId) {
      this.$swal
        .fire({
          title: "Apakah Kamu Yakin ?",
          text: "Data tidak dapat dikembalikan!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Ya, Hapus!",
        })
        .then((result) => {
          if (result.value) {
            this.$http
              .delete(this.apiUrl.clothPhoto + "/" + clothPhotoId)
              .then(() => {
                this.isLoading = false;
                this.$store.dispatch(
                  "notification/success",
                  "Warna List Pakaian Berhasil Dihapus."
                );
                // this.getDetail();
                this.getClothPhoto();
              })
              .catch((error) => {
                this.isLoading = false;
                this.$store.dispatch("notification/error", error);
              });
          }
        });
    },

    formattedDate(value) {
      return convertDateTime(value);
    },

    formattedPrice(value) {
      return convertPrice(value);
    },

    async getColor() {
      try {
        // this.isLoading = true;
        await axios.get(`${this.apiUrl.color}?limit=1000`).then((response) => {
          this.setColor = response.data.data;
          this.isLoading = false;
        });
      } catch (error) {
        console.error(error);
        this.isLoading = false;
      }
    },

    async getSize() {
      try {
        // this.isLoading = true;
        await axios.get(`${this.apiUrl.size}?limit=1000`).then((response) => {
          this.setSize = response.data.data;
          this.setSizeModal = response.data.data;
          this.isLoading = false;
        });
      } catch (error) {
        console.error(error);
        this.isLoading = false;
      }
    },

    async getClothPriceType() {
      try {
        this.isLoading = true;
        await axios
          .get(`${this.apiUrl.clothPriceType}?limit=1000`)
          .then((response) => {
            this.setClothPriceType = response.data.data;
            this.isLoading = false;
          });
      } catch (error) {
        console.error(error);
      }
    },

    async getClothPhoto() {
      try {
        this.isLoading = true;
        await axios
          .get(`${this.apiUrl.clothPhoto}?cloth_id=${this.clothPhoto.cloth_id}`)
          .then((response) => {
            this.setPhoto = response.data.data;
            this.isLoading = false;
          });
      } catch (error) {
        console.error(error);
      }
    },

    async changeStatus(check) {
      if (this.setData.status == check) {
        try {
          this.isDisabled = true;
          this.isLoading = true;
          const setStatus = check ? 1 : 0;
          await axios
            .patch(
              `${this.apiUrl.cloth}/${this.$route.params.id}`,
              { status: setStatus },
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then(async () => {
              this.isLoading = false;
              await this.getDetail();
              this.$notify({
                message: "Data Berhasil di Simpan",
                position: "bottom-right",
                type: "success",
                duration: 5000,
              });
            });
        } catch (error) {
          this.isLoading = false;
          if (process.env.NODE_ENV !== "production") {
            console.log(error);
          }
          this.$store.dispatch("notification/error", error);
          this.$notify.error({
            message: this.notification.message,
            position: "bottom-right",
            duration: 5000,
          });
        }
      }
    },

    showModalColorPhoto(id) {
      this.colorPhoto.id_color = id;
      this.$refs["modal-color-photo"].show();
    },

    hideModalColorPhoto() {
      this.$refs["modal-color-photo"].hide();
    },

    showModalPhoto() {
      this.$refs["modal-cloth-photo"].show();
    },

    hideModalPhoto() {
      this.$refs["modal-cloth-photo"].hide();
    },

    showModalColor() {
      this.$v.checkedColors.$reset();
      this.$refs["modal-cloth-color"].show();
    },

    hideModalColor() {
      if (this.$refs["modal-cloth-color"]) {
        this.$refs["modal-cloth-color"].hide();
      }
      this.setFields.cloth_color = [];
      this.selectAllColors = false;
      this.selectAllSizes = false;
      this.checkedColors = [];
      this.checkedSizes = [];
    },

    showModalSize() {
      this.$refs["modal-cloth-size"].show();
    },

    hideModalSize() {
      this.$refs["modal-cloth-size"].hide();
      this.setFields2.cloth_size = [];
      this.selectAllSizes2 = false;
      this.checkedSizes2 = [];
    },

    showModalSizeEdit(spesific_cloth_size) {
      this.spesificIdSize = spesific_cloth_size.id;
      this.spesificStock = spesific_cloth_size.stock;
      this.spesificMinStock = spesific_cloth_size.min_stock;
      this.spesificPrices = spesific_cloth_size.cloth_size_prices;
      this.$refs["modal-cloth-size-edit"].show();
    },

    hideModalSizeEdit() {
      this.$refs["modal-cloth-size-edit"].hide();
    },

    onSubmitColor() {
      // this.$v.setFields.$touch();
      this.$v.checkedColors.$touch(false);
      // this.$v.checkedSizes.$touch();
      if (
        // !this.$v.setFields.$invalid &&
        !this.$v.checkedColors.$invalid
        // !this.$v.checkedSizes.$invalid
      ) {
        this.isLoading = true;
        this.$http
          .post(`${this.apiUrl.cloth}/${this.$route.params.id}/color`, {
            cloth_color_ids: this.checkedColors,
          })
          .then(async () => {
            this.isLoading = false;
            this.hideModalColor();
            this.$store.dispatch(
              "notification/success",
              "Data berhasil disimpan"
            );
            this.$v.checkedColors.$reset();
            this.getDetail();
          })
          .catch((error) => {
            console.log("testing");
            this.isLoading = false;
            if (process.env.NODE_ENV !== "production") {
              console.log(error);
            }
            this.$store.dispatch("notification/error", error);
          });
      }
    },

    updateSku(colorId, sku) {
      if (!this.skus) {
        this.skus = {};
      }
      this.$set(this.skus, colorId, sku);
    },

    async onSubmitSize() {
      this.$v.checkedSizes2.$touch();
      if (!this.$v.checkedSizes2.$invalid) {
        try {
          console.log(this.checkedSizes2);
          this.isLoading = true;

          // Check if the size with the same ID already exists
          const existingSize = this.checkedSizes2.find(
            (size) => size === this.clothSizeId
          );
          if (existingSize) {
            this.isLoading = false;
            this.$store.dispatch(
              "notification/error",
              "Ukuran pakaian dengan ID yang sama sudah ada"
            );
            return;
          }

          await this.$http
            .post(
              `${this.apiUrl.cloth}/${this.$route.params.id}/color/${this.clothSizeId}/size`,
              {
                cloth_size_ids: this.checkedSizes2,
              }
            )
            .then(async () => {
              this.isLoading = false;
              await this.getDetail();
              this.$store.dispatch(
                "notification/success",
                "Data berhasil disimpan"
              );
            });
        } catch (error) {
          this.isLoading = false;
          if (process.env.NODE_ENV !== "production") {
            console.log(error);
          }
          this.$store.dispatch("notification/error", error);
        }
      }
    },

    async onSubmitClothPhoto() {
      this.$v.clothPhoto.$touch();
      if (!this.$v.clothPhoto.$invalid) {
        try {
          this.isLoading = true;
          await axios
            .post(
              `${this.apiUrl.clothPhoto}`,
              {
                cloth_id: this.clothPhoto.cloth_id,
                image: this.clothPhoto.image,
              },
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then(async () => {
              this.isLoading = false;
              await this.getDetail();
              this.getClothPhoto();
              this.$store.dispatch(
                "notification/success",
                "Data berhasil disimpan"
              );
            });
        } catch (error) {
          this.isLoading = false;
          if (process.env.NODE_ENV !== "production") {
            console.log(error);
          }
          this.$store.dispatch("notification/error", error);
        }
      }
    },

    async onSubmitColorPhoto() {
      try {
        this.isLoading = true;
        await axios
          .post(
            `${this.apiUrl.clothColors}/${this.colorPhoto.id_color}`,
            {
              _method: "PATCH",
              image: this.colorPhoto.image,
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(async () => {
            this.isLoading = false;
            await this.getDetail();
            this.$store.dispatch(
              "notification/success",
              "Data berhasil disimpan"
            );
          });
      } catch (error) {
        this.isLoading = false;
        if (process.env.NODE_ENV !== "production") {
          console.log(error);
        }
        this.$store.dispatch("notification/error", error);
      }
    },

    returnBack() {
      return this.$router.go(-1);
    },

    async onSubmitEditSize() {
      this.$swal
        .fire({
          title: "Apakah Kamu Mau Mengganti Semua Harga Disetiap Ukuran?",
          text: "",
          icon: "warning",
          showDenyButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Ya!",
          denyButtonText: "Tidak!",
        })
        .then((result) => {
          if (result.value) {
            this.processToSubmitClothSizePrice(true);
          } else {
            this.processToSubmitClothSizePrice();
          }
        });
    },

    processToSubmitClothSizePrice(savePriceAllSize = false) {
      const updateObject = {
        save_price_all_size: savePriceAllSize ? true : false,
        stock: Number(this.spesificStock),
        min_stock: Number(this.spesificMinStock),
        cloth_size_prices: this.spesificPrices.map((price) => {
          console.log(price);
          return {
            id: price.id,
            cloth_price_type_id: price.cloth_price_type_id,
            price: Number(price.price),
          };
        }),
      };

      console.log(updateObject);
      this.isLoadingButton = true;
      this.$http
        .patch(`${this.apiUrl.clothSizes}/${this.spesificIdSize}`, updateObject)
        .then(async () => {
          this.isLoadingButton = false;
          this.getDetail();
          this.hideModalSizeEdit();
          this.$store.dispatch(
            "notification/success",
            "Data berhasil diupdate"
          );
        });
    },

    async onDeleteSize(clothSizeId) {
      try {
        this.$swal
          .fire({
            title: "Apakah Kamu Yakin ?",
            text: "Data tidak dapat dikembalikan!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#34c38f",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Ya, Hapus!",
          })
          .then((result) => {
            if (result.value) {
              this.isLoadingButton = true;
              this.$http
                .delete(`${this.apiUrl.clothSizes}/` + clothSizeId)
                .then(async () => {
                  this.isLoadingButton = false;
                  this.getDetail();
                  this.hideModalSizeEdit();
                  this.$store.dispatch(
                    "notification/success",
                    "Data berhasil diupdate"
                  );
                });
            }
          });
      } catch (error) {
        this.isLoadingButton = false;
        if (process.env.NODE_ENV !== "production") {
          console.log(error);
        }
        this.$store.dispatch("notification/error", error);
      }
    },

    handleGetColorId(colorId, clothSizeId) {
      this.colorId = colorId;
      this.clothSizeId = clothSizeId;
      // console.log(colorId);
      // console.log(clothSizeId);
      // alert(this.colorId)
    },
    closeModalComponent() {
      window.location.reload()
      // this.getDetail();
    },
    checkFunction() {
      // console.log(this.setData);
    },
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout v-if="screenWidth >= 860" :key="componentKey">
    <PageHeader :title="$route.name" :items="items" />
    <div v-if="!isLoading && setData.cloth_colors">
      <b-card class="min-height">
        <b-card-body v-if="setData.cloth_category">
          <template v-if="$store.getters['account/accountData'].role == 'owner'">
            <div class="d-flex justify-content-end align-items-center">
              <div class="form-check form-switch form-switch-lg">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="customSwitchsizelg"
                  v-model="setData.status"
                  @change="changeStatus(setData.status)"
                />
              </div>
            </div>
          </template>
          <b-row>
            <b-row>
              <b-col class="d-flex flex-column col-md-3 my-2">
                <span class="fs-14-custom mb-1">Kategori Pakaian</span>
                <p class="fs-16-custom">
                  {{ setData.cloth_category.name }}
                </p>
              </b-col>
              <b-col class="d-flex flex-column col-md-3 my-2">
                <span class="fs-14-custom mb-1">Dibuat Oleh</span>
                <p class="fs-16-custom">
                  {{ setData.created_by.name }}
                </p>
              </b-col>
              <b-col class="d-flex flex-column col-md-3 my-2">
                <span class="fs-14-custom mb-1">Diperbarui Oleh</span>
                <p class="fs-16-custom">
                  {{
                    this.setData.updated_by !== null
                      ? this.setData.updated_by.name
                      : "-"
                  }}
                </p>
              </b-col>
              <b-col class="d-flex flex-column col-md-3 my-2">
                <span class="fs-14-custom mb-1">Tanggal Dibuat</span>
                <p class="fs-16-custom">
                  {{ this.formattedDate(this.setData.created_at) }}
                </p>
              </b-col>
              <b-col class="d-flex flex-column col-md-3 my-2">
                <span class="fs-14-custom mb-1">Tanggal Diperbarui</span>
                <p class="fs-16-custom">
                  {{ this.formattedDate(this.setData.updated_at) }}
                </p>
              </b-col>
            </b-row>
          </b-row>
        </b-card-body>
      </b-card>
      <div>
        <b-tabs pills content-class="py-2 text-muted">
          <b-tab active lazy fade class="border-0">
            <template v-slot:title>
              <span class="">Photo</span>
            </template>
            <b-card>
              <b-card-body>
                <b-row>
                  <b-col class="">
                    <span class="fs-2 fw-bold text-dark"> List Foto </span>
                  </b-col>
                </b-row>
                <template v-if="$store.getters['account/accountData'].role == 'owner'">
                  <b-row class="p-2 d-flex flex-wrap">
                    <b-col md="2" v-for="photo in setPhoto" :key="photo.id">
                      <b-img-lazy
                        :src="`${photo.image}`"
                        alt=""
                        srcset=""
                        width="100%"
                        height="190"
                        class="rounded shadow img-custom"
                        style="object-fit: cover; width: 100%"
                      />
                      <b-button
                        @click="deletedClothPhoto(photo.id)"
                        size="xs"
                        variant="danger"
                        class="custom-btn-hover"
                        style="position: absolute;top: -17px;right: -5px;border-radius: 100px;font-size: 12px;"
                      >
                        <i class="fa fa-times"></i>
                      </b-button>
                    </b-col>
                    <b-col md="2">
                      <button
                        class="btn border-dark add-detail-photo"
                        @click="showModalPhoto"
                      >
                        <i class="uil uil-plus"></i>
                      </button>
                    </b-col>
                  </b-row>
                </template>
              </b-card-body>
            </b-card>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              <span
                @click="
                  handleGetColorId(
                    setData.cloth_colors[0].color.id,
                    setData.cloth_colors[0].id
                  )
                "
                >Warna</span
              >
            </template>
            <b-card>
              <b-card-body>
                <b-row align-h="between" class="align-items-center">
                  <b-col class="d-flex justify-content-start align-items-center"
                    ><span class="fw-bold fs-4">List Warna</span></b-col
                  >
                  <b-col class="text-end">
                    <div class="d-flex justify-content-end">
                      <modalEditMinStock
                        v-if="$store.getters['account/accountData'].role == 'owner' || $store.getters['account/accountData'].role == 'manager'"
                        @closeModalComponent="closeModalComponent"
                        :minStock="
                          setData.cloth_colors[0].cloth_sizes[0].min_stock
                        "
                      />
                      <modalEditPrice
                        v-if="$store.getters['account/accountData'].role == 'owner' || $store.getters['account/accountData'].role == 'finance'"
                        @closeModalComponent="closeModalComponent"
                        :dataClothSizes="setData.cloth_colors[0].cloth_sizes"
                      />
                      <modalAddSize
                        v-if="$store.getters['account/accountData'].role == 'owner'"
                        @closeModalComponent="closeModalComponent"
                        :dataClothSizes="setData.cloth_colors[0].cloth_sizes"
                        :dataSizes="setSizeModal"
                      />
                      <b-button
                        v-if="$store.getters['account/accountData'].role == 'owner'"
                        type="button"
                        class="btn btn-success mx-2 waves-effect waves-light fw-bold"
                        @click="showModalColor"
                        >Tambah Warna</b-button
                      >
                    </div>
                  </b-col>
                </b-row>
                <div class="custom-tab">
                  <b-tabs pills content-class="text-muted">
                    <b-tab
                      lazy
                      fade
                      class="border-0"
                      v-for="cloth_color in setData.cloth_colors"
                      :key="cloth_color.id"
                    >
                      <template v-slot:title>
                        <span
                          class="position-relative"
                          @click="
                            handleGetColorId(
                              cloth_color.color.id,
                              cloth_color.id
                            )
                          "
                        >
                          {{ cloth_color.color ? cloth_color.color.name : "-" }}
                          <span
                            :style="{
                              bottom: `${8}px`,
                              right: `${-30}px`,
                              background: `#${
                                cloth_color.color.code_hexa !== null
                                  ? cloth_color.color.code_hexa
                                  : ''
                              }`,
                            }"
                            class="position-absolute translate-middle p-2 border rounded-circle"
                          >
                          </span>
                        </span>
                      </template>
                      <div class="border border-secondary my-2"></div>
                      <b-row>
                        <b-col md="3">
                          <h5 class="fw-bold fs-6">Photo Warna Pakaian</h5>
                          <template v-if="$store.getters['account/accountData'].role == 'owner'">
                            <div v-if="cloth_color.image">
                              <div
                                class="add-photo-color edit-photo shadow"
                                :style="{
                                  backgroundImage: `url(${apiUrl.baseUrl}storage/${cloth_color.image})`,
                                  border: 'none',
                                }"
                              >
                                <el-button
                                  @click="showModalColorPhoto(cloth_color.id)"
                                  icon="el-icon-edit"
                                  class="edit-photo-color"
                                />
                              </div>
                            </div>
                            <div v-else cols="3" class="d-flex flex-column">
                              <el-button
                                @click="showModalColorPhoto(cloth_color.id)"
                                icon="el-icon-plus"
                                class="add-photo-color"
                              />
                            </div>
                          </template>
                        </b-col>
                        <b-col md="3">
                          <h5 class="fw-bold fs-6">SKU</h5>

                          <template v-if="$store.getters['account/accountData'].role == 'owner'">
                            <div v-if="!editing">
                              <span
                                @click="showEditForm(cloth_color.sku_code)"
                                class="fw-bold text-dark fs-3"
                                style="cursor: pointer"
                              >
                                {{
                                  cloth_color.sku_code !== null
                                    ? cloth_color.sku_code
                                    : "-"
                                }}
                              </span>
                            </div>
                            <div
                              v-else
                              class="d-flex justify-content-start align-items-start gap-2 mt-2"
                            >
                              <input
                                type="text"
                                class="form-control"
                                v-model="editSku"
                              />
                              <div class="d-flex">
                                <el-button
                                  @click="
                                    saveEditSku(cloth_color.id, cloth_color.sku)
                                  "
                                  type="primary"
                                  icon="el-icon-check"
                                />
                                <el-button
                                  @click="cancelEditSku"
                                  type="danger"
                                  icon="el-icon-close"
                                />
                              </div>
                            </div>
                          </template>
                          <template v-else>
                            <span
                              class="fw-bold text-dark fs-3"
                            >
                              {{
                                cloth_color.sku_code !== null
                                  ? cloth_color.sku_code
                                  : "-"
                              }}
                            </span>
                          </template>
                        </b-col>
                        <b-col md="6" class="text-right">
                          <el-button
                            v-if="$store.getters['account/accountData'].role == 'owner'"
                            type="danger"
                            icon="el-icon-delete"
                            @click="deletedClothColor(cloth_color.id)"
                          />
                        </b-col>
                      </b-row>
                      <b-row class="mt-3">
                        <b-col
                          class="d-flex justify-content-start align-items-center"
                          ><span class="fw-bold fs-4" style="color: #333;"
                            >List Ukuran</span
                          ></b-col
                        >
                        <!-- <b-col class="text-end">
                          <b-button
                            variant="success"
                            @click="showModalSize"
                            class="mx-2 mx-2 waves-effect waves-light fw-bold"
                            >Tambah Ukuran</b-button
                          >
                        </b-col> -->
                      </b-row>
                      <!-- <div class="border border-dark my-2"></div> -->
                      <div
                        class="py-4"
                        v-for="cloth_size in cloth_color.cloth_sizes"
                        :key="cloth_size.id"
                      >
                        <table
                          class="table table-bordered border-custom"
                          style="background-color: #fff;"
                        >
                          <thead>
                            <tr class="text-left">
                              <th
                                :colspan="
                                  cloth_size.cloth_size_prices.length + 3
                                "
                              >
                                <h5 class="fw-bold">
                                  Ukuran {{ cloth_size.size.name }} ({{
                                    cloth_size.sku_code
                                  }})
                                </h5>
                              </th>
                            </tr>
                            <tr class="text-center">
                              <th>Stok</th>
                              <th>Min-Stok</th>
                              <th
                                width="180"
                                class="text-right"
                                v-for="cloth_size_price in cloth_size.cloth_size_prices"
                                :key="cloth_size_price.id"
                                v-if="cloth_size_price.cloth_price_type"
                              >
                                {{ cloth_size_price.cloth_price_type.name }}
                              </th>
                              <th v-if="$store.getters['account/accountData'].role == 'owner'">Aksi</th>
                            </tr>
                          </thead>
                          <tbody class="">
                            <tr class="text-center">
                              <td width="180">
                                {{ cloth_size.stock }}
                                <!-- <div v-if="true">
                                  <input
                                    class="form-control"
                                    type="number"
                                    :placeholder="spesificStock"
                                    :id="'price-' + spesificStock"
                                    name="select-all-colors"
                                    v-model="spesificStock"
                                  />
                                  <div class="my-2">
                                    <el-button
                                      @click="showModalSizeEdit(cloth_size)"
                                      type="success"
                                      icon="el-icon-check"
                                    />
                                    <el-button
                                      type="danger"
                                      icon="el-icon-close"
                                      @click="onDeleteSize(cloth_size.id)"
                                    />
                                  </div>
                                </div>
                                <span v-else>
                                  {{ cloth_size.stock }}
                                </span> -->
                              </td>
                              <td width="180">{{ cloth_size.min_stock }}</td>
                              <td
                                class="text-right"
                                width="180"
                                v-for="cloth_size_price in cloth_size.cloth_size_prices"
                                :key="cloth_size_price.id"
                              >
                                {{ formattedPrice(cloth_size_price.price) }}
                              </td>
                              <td width="180" v-if="$store.getters['account/accountData'].role == 'owner'">
                                <div
                                  class="mb-1 p-1 d-flex justify-content-center align-items-center"
                                >
                                  <!-- <el-button
                                    @click="showModalSizeEdit(cloth_size)"
                                    type="primary"
                                    icon="el-icon-edit"
                                  /> -->
                                  <el-button
                                    type="danger"
                                    icon="el-icon-delete"
                                    @click="onDeleteSize(cloth_size.id)"
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </b-card-body>
            </b-card>
          </b-tab>
          <b-tab>
            <template v-slot:title>
              History
            </template>
            <b-card>
              <b-card-body>
                <b-row>
                  <b-col class="">
                    <span class="fs-2 fw-bold text-dark">History Pakaian</span>
                  </b-col>
                </b-row>
                <b-row>
                  <custom-table
                    ref="dataTable"
                    :data-url="apiUrl.clothHistory"
                    :data-columns="columns"
                    :action="false"
                    :with-form-add="false"
                    :with-history="this.$route.params.id"
                    :datas="this.setData"
                    form-url="false"
                    data-key="History"
                  ></custom-table>
                </b-row>
              </b-card-body>
            </b-card>
          </b-tab>
        </b-tabs>
      </div>
    </div>

    <template v-if="!isLoading">
      <!-- Modal add Cloth Photo -->
      <b-modal
        ref="modal-cloth-photo"
        size="lg"
        title="Upload Photo"
        title-class="font-18"
        hide-footer
        centered
      >
        <form
          enctype="multipart/form-data"
          @submit.prevent="onSubmitClothPhoto"
        >
          <label for="formFileUpload">Masukkan Photo</label>
          <b-form-file
            id="formFileUpload"
            v-model="clothPhoto.image"
            accept="image/*"
          ></b-form-file>
          <p v-if="clothPhotoErrors.$error" class="text-danger">
            Harap masukkan foto
          </p>
          <b-button type="submit" variant="primary" class="mt-3 text-center"
            >Simpan</b-button
          >
        </form>
      </b-modal>

      <!-- Modal Color Photo -->
      <b-modal
        ref="modal-color-photo"
        size="lg"
        title="Upload Photo"
        title-class="font-18"
        hide-footer
        centered
      >
        <form @submit.prevent="onSubmitColorPhoto()">
          <label for="formFileUpload">Masukkan Photo Warna Pakaian</label>
          <b-form-file
            v-model="colorPhoto.image"
            id="formFileUpload"
            accept="image/*"
          ></b-form-file>
          <b-button type="submit" variant="primary" class="mt-3 text-center"
            >Simpan</b-button
          >
        </form>
      </b-modal>

      <!-- Modal Cloth Color -->
      <b-modal
        ref="modal-cloth-color"
        title="Tambah Warna"
        title-class="font-18"
        hide-footer
        centered
      >
        <div class="mx-3">
          <form enctype="multipart/form-data" @submit.prevent="onSubmitColor">
            <div id="parent">
              <!-- <b-row>
                <h5 class="fw-bold text-dark">
                  Filter Warna dan Ukuran Pakaian
                </h5>
                <b-col class="fw-bold"> Pilih Warna Pakaian </b-col>
              </b-row> -->
              <!-- <b-row class="mt-2">
                <b-col>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      id="select-all-colors"
                      name="select-all-colors"
                      value="1"
                      v-model="selectAllColors"
                    />
                    <label for="select-all-colors" class="form-check-label"
                      >Pilih Semua Warna</label
                    >
                  </div>
                </b-col>
              </b-row> -->

              <b-row>
                <label class="fw-bold"> Warna Produk </label>
              </b-row>
              <b-row class="mt-2">
                <b-col class="d-flex flex-wrap gap-2">
                  <div
                    class="form-check form-check-inline d-flex justify-content-start align-items-center gap-2 color-wrap"
                    v-for="(color, index) in setColor"
                    :key="color.id"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :id="`checkbox-${color.id}-${index}`"
                      v-model="checkedColors"
                      :name="`checkbox-${color.id}-${index}`"
                      :value="color.id"
                    />
                    <span
                      class="color-indicator"
                      :style="{ background: `#${color.code_hexa}` }"
                    ></span>
                    <label
                      class="form-check-label"
                      :for="`checkbox-${color.id}-${index}`"
                      ><span class="fs-6 fw-bold">{{ color.name }}</span></label
                    >
                  </div>
                  <p v-if="checkedColorErrors.$error" class="text-danger">
                    Harap pilih warna pakaian, minimal 1
                  </p>
                </b-col>
              </b-row>
            </div>
            <div class="mt-3 d-flex justify-content-between align-items-center">
              <b-button type="submit" variant="primary" class="mt-3 float-left"
                >Simpan</b-button
              >
              <b-button class="mt-3" block @click="hideModalColor()"
                >Batal</b-button
              >
            </div>
          </form>
        </div>
      </b-modal>

      <!-- Modal Cloth Size -->
      <b-modal
        size="sm"
        ref="modal-cloth-size"
        title="Tambah Ukuran"
        title-class="font-18"
        hide-footer
        centered
      >
        <div class="mx-3">
          <form enctype="multipart/form-data" @submit.prevent="onSubmitSize">
            <div id="parent">
              <b-row>
                <b-col class="fw-bold">
                  <label>Ukuran Pakaian</label>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col>
                  <div
                    class="form-check form-check-inline"
                    v-for="(size, index) in setSize"
                    :key="size.id"
                  >
                    <input
                      class="form-check-input"
                      type="checkbox"
                      :id="`checkbox-${size.id}-${index}`"
                      v-model="checkedSizes2"
                      :name="`checkbox-${size.id}-${index}`"
                      :value="size.id"
                    />
                    <label
                      class="form-check-label"
                      :for="`checkbox-${size.id}-${index}`"
                      ><span
                        class="bg-success px-1 fs-6 rounded fw-bold text-white"
                        >{{ size.name }}</span
                      ></label
                    >
                  </div>
                  <p v-if="checkedSize2Errors.$error" class="text-danger">
                    Harap pilih warna ukuran, minimal 1
                  </p>
                </b-col>
              </b-row>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <b-button type="submit" variant="primary" class="mt-3 float-left"
                >Simpan</b-button
              >
              <b-button class="mt-3" block @click="hideModalSize()"
                >Batal</b-button
              >
            </div>
          </form>
        </div>
      </b-modal>

      <!-- Modal Cloth Size Edit -->
      <b-modal
        size="xl"
        ref="modal-cloth-size-edit"
        title="Edit Harga Ukuran"
        title-class="font-18"
        hide-footer
        centered
      >
        <form @submit.prevent="onSubmitEditSize" class="mx-3">
          <b-row>
            <b-col class="col-md-4 my-2">
              <label :for="'price-' + spesificMinStock"> Min-Stock </label>
              <input
                class="form-control"
                type="number"
                :placeholder="spesificMinStock"
                :id="'price-' + spesificMinStock"
                name="select-all-colors"
                v-model="spesificMinStock"
              />
            </b-col>
            <b-col
              v-for="prices in spesificPrices"
              :key="prices.id"
              class="col-md-4 my-2"
            >
              <label :for="'price-' + prices.cloth_price_type.id">{{
                prices.cloth_price_type.name
              }}</label>
              <input
                class="form-control"
                type="number"
                :placeholder="prices.price"
                :id="'price-' + prices.cloth_price_type.id"
                v-model="prices.price"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col class="col-md-6">
              <b-button
                type="submit"
                variant="primary"
                :disabled="isLoadingButton"
                class="w-sm"
                ><b-spinner
                  style="width: 1rem; height: 1rem; margin-right: 5px"
                  label="Loading..."
                  v-if="isLoadingButton"
                ></b-spinner>
                Update</b-button
              >
            </b-col>
            <b-col class="col-md-6 text-end">
              <b-button @click="hideModalSizeEdit()">Batal</b-button>
            </b-col>
          </b-row>
        </form>
      </b-modal>
    </template>

    <div v-else>
      <b-card>
        <b-card-body>
          <b-row>
            <b-col class="d-flex justify-content-start align-items-center">
            </b-col>
            <b-col class="d-flex justify-content-end align-items-center">
              <skeletonLoading width="85px" height="30px" borderRadius="20px" />
            </b-col>
          </b-row>
          <b-row class="mt-5 gap-5">
            <b-col class="d-flex flex-column col-md-3">
              <b-row>
                <skeletonLoading
                  class="mb-2"
                  width="80px"
                  height="25px"
                  borderRadius="5px"
                />
              </b-row>
              <b-row>
                <skeletonLoading width="70%" height="25px" borderRadius="4px" />
              </b-row>
            </b-col>
            <b-col class="d-flex flex-column col-md-3">
              <b-row>
                <skeletonLoading
                  class="mb-2"
                  width="80px"
                  height="25px"
                  borderRadius="5px"
                />
              </b-row>
              <b-row>
                <skeletonLoading width="70%" height="25px" borderRadius="4px" />
              </b-row>
            </b-col>
            <b-col class="d-flex flex-column col-md-2">
              <b-row>
                <skeletonLoading
                  class="mb-2"
                  width="80px"
                  height="25px"
                  borderRadius="5px"
                />
              </b-row>
              <b-row>
                <skeletonLoading
                  width="100%"
                  height="25px"
                  borderRadius="4px"
                />
              </b-row>
            </b-col>
            <b-col class="d-flex flex-column col-md-2">
              <b-row>
                <skeletonLoading
                  class="mb-2"
                  width="80px"
                  height="25px"
                  borderRadius="5px"
                />
              </b-row>
              <b-row>
                <skeletonLoading
                  width="100%"
                  height="25px"
                  borderRadius="4px"
                />
              </b-row>
            </b-col>
            <b-col class="d-flex flex-column col-md-2">
              <b-row>
                <skeletonLoading
                  class="mb-2"
                  width="80px"
                  height="25px"
                  borderRadius="5px"
                />
              </b-row>
              <b-row>
                <skeletonLoading
                  width="100%"
                  height="25px"
                  borderRadius="4px"
                />
              </b-row>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <div>
        <b-tabs pills content-class="py-2 text-muted">
          <b-row>
            <b-col sm="1">
              <skeletonLoading
                class="mb-2"
                width="70px"
                height="30px"
                borderRadius="4px"
              />
            </b-col>
            <b-col sm="1">
              <skeletonLoading
                class="mb-2"
                width="70px"
                height="30px"
                borderRadius="4px"
              />
            </b-col>
            <b-col sm="1">
              <skeletonLoading
                class="mb-2"
                width="70px"
                height="30px"
                borderRadius="4px"
              />
            </b-col>
          </b-row>
          <b-card>
            <b-card-body>
              <b-row>
                <b-col class="">
                  <skeletonLoading
                    class="mb-2"
                    width="20%"
                    height="30px"
                    borderRadius="4px"
                  />
                </b-col>
              </b-row>
              <b-row class="p-2">
                <b-col class="p-2 col-md-3">
                  <skeletonLoading
                    class="mb-2"
                    width="180px"
                    height="190px"
                    borderRadius="4px"
                  />
                </b-col>
                <b-col class="p-2">
                  <skeletonLoading
                    class="mb-2"
                    width="180px"
                    height="190px"
                    borderRadius="4px"
                  />
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-tabs>
      </div>
    </div>
  </Layout>

  <detailM v-else />
</template>

<style>
.nav {
  gap: 15px;
}
</style>

<style scoped>
.border-custom {
  border-color: #ebeef5 !important;
  /* display: block; */
  overflow-x: auto;
  white-space: nowrap;
}

.min-height {
  min-height: 270px;
}
</style>
